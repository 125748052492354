import React, { useContext, useState } from "react";
import { Link, navigate } from "gatsby";
import { AuthContext } from "../../../auth";
import api from "../../../services/api";
import isEmail from "validator/lib/isEmail";
import "../user.css";

const devValue = (value, falseValue = "") => {
  return process.env.NODE_ENV === "development" ? value : falseValue;
};

// markup
const LoginForm = ({ status = "" }) => {
  const [message, setMessage] = useState(null);
  const {
    actions: { login },
  } = useContext(AuthContext);

  const [progress, setProgress] = useState(false);
  const [email, setEmail] = useState(devValue("mahesh@owita.lk"));
  const [password, setPassword] = useState(devValue("password"));

  const handleLogin = async (e) => {
    e.preventDefault();
    const data = {
      email,
      password,
    };
    setMessage(null);
    setProgress(true);

    try {
      api("post", "login", data).then((res) => {
        setProgress(false);
        if (res.data.status === "error") {
          setMessage(res.data.message);
        } else if (typeof window !== "undefined" && res.data.user.id) {
          localStorage.setItem("accessToken", res.data.accessToken);
          login({ data: res.data.user, connections: res.data.connections });
          if (res.data.connections.length === 0) {
            navigate("/user/new-connection");
          } else {
            navigate("/user/dashboard");
          }
        }
      });
    } catch (error) {
      setProgress(false);
      setMessage(
        "Error occurred while login. If this error persists, please contact system administrator."
      );
      console.log(error);
    }
  };

  const disabled =
    progress || !isEmail(email) || !password || password.length < 5;

  return (
    <>
      <form
        className={"position-relative"}
        method={"post"}
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        {message && (
          <div className="position-absolute w-100" style={{ bottom: "100%" }}>
            <div className="alert alert-danger alert-icon" role="alert">
              <i className="uil uil-times-circle" /> {message}
            </div>
          </div>
        )}
        <div className="mb-4">
          <label htmlFor="Email">Email Address</label>
          <input
            type="email"
            name="Email"
            id="Email"
            placeholder="Email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            id="Password"
            placeholder="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <input
          type="submit"
          value="Login"
          disabled={disabled}
          className="btn btn-primary rounded-pill mb-4 ms-auto me-auto text-center d-block w-100"
          onClick={handleLogin}
        />
      </form>

      <article className="py-4 d-flex justify-content-center">
        <Link to={"/user/register/"}>Register</Link>
        <p className="ms-2 me-2">|</p>
        <Link to={"/user/recovery-email"}>Forgot Password?</Link>
      </article>
    </>
  );
};

export default LoginForm;
