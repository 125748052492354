import React from "react";
import Layout from "../../../layout";
import LoginForm from "./_LoginForm";
import "../user.css";

// markup
const LoginPage = () => {
  const parsed = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : ""
  );

  return (
    <Layout
      title={"Login"}
      description={"Log in to your account to access the services"}
      publicPage
    >
      <section className="login py-8 py-lg-16">
        <article className="form-wrapper ms-auto me-auto">
          {parsed.get("status") === "email-verified" ? (
            <Message />
          ) : (
            <LoginForm status={parsed.get("status")} />
          )}
        </article>
      </section>
    </Layout>
  );
};

export default LoginPage;

const Message = () => {
  return (
    <div className="uk-text-center">
      <h4>Email verified</h4>
      <p>
        One of our agents will review your account and activate it within one
        business day.
        <br />
        <br />
        We will notify you via email once the account is activated.
        <br />
        <br />
        Thank you.
      </p>
    </div>
  );
};
